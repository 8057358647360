import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue";
const _hoisted_1 = {
  style: {
    "display": "flex",
    "flex-direction": "column"
  }
};
const _hoisted_2 = {
  style: {
    "display": "flex"
  }
};
const _hoisted_3 = {
  key: 1,
  style: {
    "display": "flex",
    "width": "100%",
    "align-items": "center",
    "justify-content": "center",
    "margin": "9px 0px"
  }
};
const _hoisted_4 = {
  class: "tableHeardBox"
};
const _hoisted_5 = {
  key: 4,
  style: {
    "display": "flex",
    "width": "100%",
    "align-items": "center",
    "justify-content": "center",
    "margin": "9px 0px"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_vxe_option = _resolveComponent("vxe-option");
  const _component_vxe_optgroup = _resolveComponent("vxe-optgroup");
  const _component_vxe_select = _resolveComponent("vxe-select");
  const _component_vxe_input = _resolveComponent("vxe-input");
  const _component_vxe_button = _resolveComponent("vxe-button");
  const _component_vxe_toolbar = _resolveComponent("vxe-toolbar");
  const _component_vxe_column = _resolveComponent("vxe-column");
  const _component_vxe_table = _resolveComponent("vxe-table");
  const _component_vxe_icon = _resolveComponent("vxe-icon");
  const _component_vxe_pager = _resolveComponent("vxe-pager");
  const _component_vxe_form_item = _resolveComponent("vxe-form-item");
  const _component_vxe_form = _resolveComponent("vxe-form");
  const _component_vxe_modal = _resolveComponent("vxe-modal");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_vxe_toolbar, null, {
    buttons: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_vxe_select, {
      clearable: "",
      style: {
        "margin-right": "12px",
        "width": "300px"
      },
      modelValue: $setup.selectActivityStatsItemId,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $setup.selectActivityStatsItemId = $event),
      placeholder: "Please Select"
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.activityStatsList, item => {
        return _openBlock(), _createBlock(_component_vxe_optgroup, {
          label: item.activityName,
          key: item.activityStatsId
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.items, subItem => {
            return _openBlock(), _createBlock(_component_vxe_option, {
              key: subItem.itemId,
              value: subItem.itemId,
              label: subItem.itemName
            }, null, 8, ["value", "label"]);
          }), 128))]),
          _: 2
        }, 1032, ["label"]);
      }), 128))]),
      _: 1
    }, 8, ["modelValue"]), _createElementVNode("div", _hoisted_2, [$setup.selectActivityStatsItemId == null ? (_openBlock(), _createBlock(_component_vxe_input, {
      key: 0,
      style: {
        "width": "300px",
        "margin-top": "5px"
      },
      modelValue: $setup.statsName,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $setup.statsName = $event),
      placeholder: "Campaign Name"
    }, null, 8, ["modelValue"])) : _createCommentVNode("", true), $setup.selectActivityStatsItemId == null ? (_openBlock(), _createBlock(_component_vxe_input, {
      key: 1,
      style: {
        "width": "300px",
        "margin-top": "5px",
        "margin-left": "5px"
      },
      modelValue: $setup.itemName,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $setup.itemName = $event),
      placeholder: "Item Name"
    }, null, 8, ["modelValue"])) : _createCommentVNode("", true)])]), $setup.selectActivityStatsItemId != null ? (_openBlock(), _createBlock(_component_vxe_button, {
      key: 0,
      onClick: _cache[3] || (_cache[3] = $event => $setup.insertInfoEvent()),
      content: "Add Record",
      style: {
        "margin-right": "50px"
      }
    })) : _createCommentVNode("", true), $setup.selectActivityStatsItemId != null ? (_openBlock(), _createBlock(_component_vxe_input, {
      key: 1,
      modelValue: $setup.val1,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => $setup.val1 = $event),
      placeholder: "日期选择",
      type: "date"
    }, null, 8, ["modelValue"])) : _createCommentVNode("", true), $setup.selectActivityStatsItemId != null ? (_openBlock(), _createBlock(_component_vxe_input, {
      key: 2,
      modelValue: $setup.val2,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => $setup.val2 = $event),
      placeholder: "日期选择",
      type: "date"
    }, null, 8, ["modelValue"])) : _createCommentVNode("", true), $setup.selectActivityStatsItemId != null ? (_openBlock(), _createBlock(_component_vxe_button, {
      key: 3,
      onClick: _cache[6] || (_cache[6] = $event => $setup.dataQuery()),
      content: "查询日期范围内数据"
    })) : _createCommentVNode("", true)]),
    _: 1
  }), $setup.selectActivityStatsItemId == null ? (_openBlock(), _createBlock(_component_vxe_table, {
    key: 0,
    border: "",
    class: "test",
    "show-overflow": "",
    "keep-source": "",
    ref: "xTableColumn",
    "max-height": "400",
    data: $setup.columnData,
    "edit-config": {
      trigger: 'click',
      mode: 'row',
      showStatus: true
    }
  }, {
    default: _withCtx(() => [_createVNode(_component_vxe_column, {
      field: "title",
      title: "Field Title",
      width: "300",
      "edit-render": {
        autofocus: '.vxe-input--inner'
      }
    }, {
      edit: _withCtx(({
        row
      }) => [_createVNode(_component_vxe_input, {
        placeholder: "Please Input",
        modelValue: row.title,
        "onUpdate:modelValue": $event => row.title = $event,
        type: "text"
      }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
      _: 1
    }, 8, ["edit-render"]), _createVNode(_component_vxe_column, {
      field: "name",
      title: "Field Name",
      width: "200",
      "edit-render": {
        autofocus: '.vxe-input--inner'
      }
    }, {
      edit: _withCtx(({
        row
      }) => [_createVNode(_component_vxe_input, {
        placeholder: "Please Input",
        modelValue: row.name,
        "onUpdate:modelValue": $event => row.name = $event,
        type: "text"
      }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
      _: 1
    }, 8, ["edit-render"]), _createVNode(_component_vxe_column, {
      width: "180",
      field: "type",
      title: "Field Type",
      "edit-render": {}
    }, {
      default: _withCtx(({
        row
      }) => [_createElementVNode("span", null, _toDisplayString(row.type), 1)]),
      edit: _withCtx(({
        row
      }) => [_createVNode(_component_vxe_select, {
        placeholder: "Please Select",
        modelValue: row.type,
        "onUpdate:modelValue": $event => row.type = $event,
        transfer: ""
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.typeList, item => {
          return _openBlock(), _createBlock(_component_vxe_option, {
            key: item.value,
            value: item.value,
            label: item.label
          }, null, 8, ["value", "label"]);
        }), 128))]),
        _: 2
      }, 1032, ["modelValue", "onUpdate:modelValue"])]),
      _: 1
    }), _createVNode(_component_vxe_column, {
      field: "length",
      title: "Field Length",
      width: "300",
      "edit-render": {
        autofocus: '.vxe-input--inner'
      }
    }, {
      edit: _withCtx(({
        row
      }) => [_createVNode(_component_vxe_input, {
        placeholder: "Please Input",
        modelValue: row.length,
        "onUpdate:modelValue": $event => row.length = $event,
        type: "integer"
      }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
      _: 1
    }, 8, ["edit-render"]), _createVNode(_component_vxe_column, {
      field: "scale",
      title: "Field Decimals",
      width: "300",
      "edit-render": {
        autofocus: '.vxe-input--inner'
      }
    }, {
      edit: _withCtx(({
        row
      }) => [_createVNode(_component_vxe_input, {
        placeholder: "Please Input",
        modelValue: row.scale,
        "onUpdate:modelValue": $event => row.scale = $event,
        type: "integer"
      }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
      _: 1
    }, 8, ["edit-render"]), _createVNode(_component_vxe_column, {
      width: "180",
      field: "required",
      title: "Required",
      "edit-render": {}
    }, {
      default: _withCtx(({
        row
      }) => [_createElementVNode("span", null, _toDisplayString($setup.fromRequiredLabel(row.required)), 1)]),
      edit: _withCtx(({
        row
      }) => [_createVNode(_component_vxe_select, {
        placeholder: "Please Select",
        modelValue: row.required,
        "onUpdate:modelValue": $event => row.required = $event,
        transfer: ""
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.requiredList, item => {
          return _openBlock(), _createBlock(_component_vxe_option, {
            key: item.value,
            value: item.value,
            label: item.label
          }, null, 8, ["value", "label"]);
        }), 128))]),
        _: 2
      }, 1032, ["modelValue", "onUpdate:modelValue"])]),
      _: 1
    }), _createVNode(_component_vxe_column, {
      title: "Operation",
      width: "100",
      "show-overflow": ""
    }, {
      default: _withCtx(({
        row
      }) => [row._X_ROW_KEY === $setup.rowKey ? (_openBlock(), _createBlock(_component_vxe_button, {
        key: 0,
        type: "text",
        icon: "vxe-icon-add",
        onClick: _cache[7] || (_cache[7] = $event => $setup.insertEvent())
      })) : _createCommentVNode("", true), _createVNode(_component_vxe_button, {
        type: "text",
        icon: "vxe-icon-delete",
        onClick: $event => $setup.removeEvent(row)
      }, null, 8, ["onClick"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["data"])) : _createCommentVNode("", true), $setup.selectActivityStatsItemId == null ? (_openBlock(), _createElementBlock("div", _hoisted_3, [_createVNode(_component_vxe_button, {
    onClick: _cache[8] || (_cache[8] = $event => $setup.saveEvent()),
    content: "Submit"
  })])) : _createCommentVNode("", true), $setup.selectActivityStatsItemId != null ? (_openBlock(), _createBlock(_component_vxe_table, {
    key: 2,
    border: "",
    class: "test table1",
    "show-overflow": "",
    "keep-source": "",
    ref: "xTableActivityParticipantInfo",
    "max-height": "400",
    data: $setup.activityData.activityStatsItemData,
    "edit-config": {
      trigger: 'click',
      mode: 'row',
      showStatus: true
    }
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.activityData.activityColumnList, (column, index) => {
      return _openBlock(), _createBlock(_component_vxe_column, {
        key: index,
        field: column.name,
        title: column.title,
        "edit-render": {
          autofocus: '.vxe-input--inner'
        }
      }, {
        header: _withCtx(({
          column,
          index
        }) => [_createElementVNode("div", _hoisted_4, [_createElementVNode("span", null, _toDisplayString(column.title), 1), _createVNode(_component_vxe_icon, {
          name: "setting",
          onClick: $event => $setup.editHeaderClick(column),
          style: {
            "margin-left": "5px",
            "cursor": "pointer"
          }
        }, null, 8, ["onClick"]), _createVNode(_component_vxe_icon, {
          name: "delete",
          onClick: $event => $setup.deleteHeaderClick(column),
          style: {
            "margin-left": "5px",
            "cursor": "pointer"
          }
        }, null, 8, ["onClick"]), _createVNode(_component_vxe_icon, {
          name: "add",
          onClick: $event => $setup.addHeaderClick(column),
          style: {
            "margin-left": "5px",
            "cursor": "pointer"
          }
        }, null, 8, ["onClick"])])]),
        edit: _withCtx(({
          row
        }) => [_createVNode(_component_vxe_input, {
          modelValue: row[column.name],
          "onUpdate:modelValue": $event => row[column.name] = $event,
          placeholder: "Please Input",
          type: "text"
        }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
        _: 2
      }, 1032, ["field", "title", "edit-render"]);
    }), 128)), _createVNode(_component_vxe_column, {
      title: "Operation",
      width: "100",
      "show-overflow": ""
    }, {
      default: _withCtx(({
        row
      }) => [_createVNode(_component_vxe_button, {
        type: "text",
        icon: "vxe-icon-delete",
        onClick: $event => $setup.removeEvent2(row)
      }, null, 8, ["onClick"])]),
      _: 1
    })]),
    _: 1
  }, 8, ["data"])) : _createCommentVNode("", true), $setup.selectActivityStatsItemId != null ? (_openBlock(), _createBlock(_component_vxe_pager, {
    key: 3,
    "current-page": $setup.pageVO1.currentPage,
    "onUpdate:currentPage": _cache[9] || (_cache[9] = $event => $setup.pageVO1.currentPage = $event),
    "page-size": $setup.pageVO1.pageSize,
    "onUpdate:pageSize": _cache[10] || (_cache[10] = $event => $setup.pageVO1.pageSize = $event),
    total: $setup.pageVO1.total,
    layouts: ['PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'Sizes', 'FullJump', 'Total'],
    onPageChange: $setup.pageChangeEvent1
  }, null, 8, ["current-page", "page-size", "total", "onPageChange"])) : _createCommentVNode("", true), $setup.selectActivityStatsItemId != null ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createVNode(_component_vxe_button, {
    onClick: _cache[11] || (_cache[11] = $event => $setup.saveStatsEvent()),
    content: "Submit"
  })])) : _createCommentVNode("", true), _createVNode(_component_vxe_modal, {
    modelValue: $setup.showEdit,
    "onUpdate:modelValue": _cache[13] || (_cache[13] = $event => $setup.showEdit = $event),
    resize: "",
    "destroy-on-close": "",
    width: "800",
    height: "350",
    "min-width": "600",
    "min-height": "500",
    title: '编辑&保存'
  }, {
    default: _withCtx(() => [_createVNode(_component_vxe_form, {
      data: $setup.activityData.editTableHeard2
    }, {
      default: _withCtx(() => [_createVNode(_component_vxe_form_item, {
        field: "title",
        title: "Field Title",
        span: 12,
        "item-render": {}
      }, {
        default: _withCtx(({
          data
        }) => [_createVNode(_component_vxe_input, {
          modelValue: data.title,
          "onUpdate:modelValue": $event => data.title = $event,
          placeholder: "Please Input"
        }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
        _: 1
      }), _createVNode(_component_vxe_form_item, {
        field: "name",
        title: "Field Name",
        span: 12,
        "item-render": {}
      }, {
        default: _withCtx(({
          data
        }) => [_createVNode(_component_vxe_input, {
          modelValue: data.name,
          "onUpdate:modelValue": $event => data.name = $event,
          placeholder: "Please Input"
        }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
        _: 1
      }), _createVNode(_component_vxe_form_item, {
        field: "type",
        title: "Field Type",
        span: 12,
        "item-render": {}
      }, {
        default: _withCtx(({
          data
        }) => [_createVNode(_component_vxe_select, {
          modelValue: data.type,
          "onUpdate:modelValue": $event => data.type = $event,
          transfer: ""
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.typeList, item => {
            return _openBlock(), _createBlock(_component_vxe_option, {
              key: item.value,
              value: item.value,
              label: item.label
            }, null, 8, ["value", "label"]);
          }), 128))]),
          _: 2
        }, 1032, ["modelValue", "onUpdate:modelValue"])]),
        _: 1
      }), _createVNode(_component_vxe_form_item, {
        field: "lengthe",
        title: "Field Length",
        span: 12,
        "item-render": {}
      }, {
        default: _withCtx(({
          data
        }) => [_createVNode(_component_vxe_input, {
          modelValue: data.length,
          "onUpdate:modelValue": $event => data.length = $event,
          type: "number",
          placeholder: "Please Input"
        }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
        _: 1
      }), _createVNode(_component_vxe_form_item, {
        field: "scale",
        title: "Field Decimals",
        span: 12,
        "item-render": {}
      }, {
        default: _withCtx(({
          data
        }) => [_createVNode(_component_vxe_input, {
          modelValue: data.scale,
          "onUpdate:modelValue": $event => data.scale = $event,
          type: "number",
          placeholder: "Please Input"
        }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
        _: 1
      }), _createVNode(_component_vxe_form_item, {
        field: "required",
        title: "Field Required",
        span: 12,
        "item-render": {}
      }, {
        default: _withCtx(({
          data
        }) => [_createVNode(_component_vxe_select, {
          modelValue: data.required,
          "onUpdate:modelValue": $event => data.required = $event,
          transfer: ""
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.requiredList, item => {
            return _openBlock(), _createBlock(_component_vxe_option, {
              key: item.value,
              value: item.value,
              label: item.label
            }, null, 8, ["value", "label"]);
          }), 128))]),
          _: 2
        }, 1032, ["modelValue", "onUpdate:modelValue"])]),
        _: 1
      }), _createVNode(_component_vxe_form_item, {
        align: "center",
        "title-align": "left",
        span: 24
      }, {
        default: _withCtx(() => [_createVNode(_component_vxe_button, {
          mode: "text",
          style: {
            "margin-top": "30px"
          },
          onClick: _cache[12] || (_cache[12] = $event => $setup.modifyApi())
        }, {
          default: _withCtx(() => [_createTextVNode("提交修改")]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }, 8, ["data"])]),
    _: 1
  }, 8, ["modelValue"]), _createVNode(_component_vxe_modal, {
    modelValue: $setup.showEdit2,
    "onUpdate:modelValue": _cache[15] || (_cache[15] = $event => $setup.showEdit2 = $event),
    resize: "",
    "destroy-on-close": "",
    width: "800",
    height: "350",
    "min-width": "600",
    "min-height": "500",
    title: '编辑&保存'
  }, {
    default: _withCtx(() => [_createVNode(_component_vxe_form, {
      data: $setup.activityData.editTableHeard3
    }, {
      default: _withCtx(() => [_createVNode(_component_vxe_form_item, {
        field: "title",
        title: "Field Title",
        span: 12,
        "item-render": {}
      }, {
        default: _withCtx(({
          data
        }) => [_createVNode(_component_vxe_input, {
          modelValue: data.title,
          "onUpdate:modelValue": $event => data.title = $event,
          placeholder: "Please Input"
        }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
        _: 1
      }), _createVNode(_component_vxe_form_item, {
        field: "name",
        title: "Field Name",
        span: 12,
        "item-render": {}
      }, {
        default: _withCtx(({
          data
        }) => [_createVNode(_component_vxe_input, {
          modelValue: data.name,
          "onUpdate:modelValue": $event => data.name = $event,
          placeholder: "Please Input"
        }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
        _: 1
      }), _createVNode(_component_vxe_form_item, {
        field: "type",
        title: "Field Type",
        span: 12,
        "item-render": {}
      }, {
        default: _withCtx(({
          data
        }) => [_createVNode(_component_vxe_select, {
          modelValue: data.type,
          "onUpdate:modelValue": $event => data.type = $event,
          transfer: ""
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.typeList, item => {
            return _openBlock(), _createBlock(_component_vxe_option, {
              key: item.value,
              value: item.value,
              label: item.label
            }, null, 8, ["value", "label"]);
          }), 128))]),
          _: 2
        }, 1032, ["modelValue", "onUpdate:modelValue"])]),
        _: 1
      }), _createVNode(_component_vxe_form_item, {
        field: "lengthe",
        title: "Field Length",
        span: 12,
        "item-render": {}
      }, {
        default: _withCtx(({
          data
        }) => [_createVNode(_component_vxe_input, {
          modelValue: data.length,
          "onUpdate:modelValue": $event => data.length = $event,
          type: "number",
          placeholder: "Please Input"
        }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
        _: 1
      }), _createVNode(_component_vxe_form_item, {
        field: "scale",
        title: "Field Decimals",
        span: 12,
        "item-render": {}
      }, {
        default: _withCtx(({
          data
        }) => [_createVNode(_component_vxe_input, {
          modelValue: data.scale,
          "onUpdate:modelValue": $event => data.scale = $event,
          type: "number",
          placeholder: "Please Input"
        }, null, 8, ["modelValue", "onUpdate:modelValue"])]),
        _: 1
      }), _createVNode(_component_vxe_form_item, {
        field: "required",
        title: "Field Required",
        span: 12,
        "item-render": {}
      }, {
        default: _withCtx(({
          data
        }) => [_createVNode(_component_vxe_select, {
          modelValue: data.required,
          "onUpdate:modelValue": $event => data.required = $event,
          transfer: ""
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.requiredList, item => {
            return _openBlock(), _createBlock(_component_vxe_option, {
              key: item.value,
              value: item.value,
              label: item.label
            }, null, 8, ["value", "label"]);
          }), 128))]),
          _: 2
        }, 1032, ["modelValue", "onUpdate:modelValue"])]),
        _: 1
      }), _createVNode(_component_vxe_form_item, {
        align: "center",
        "title-align": "left",
        span: 24
      }, {
        default: _withCtx(() => [_createVNode(_component_vxe_button, {
          mode: "text",
          style: {
            "margin-top": "30px"
          },
          onClick: _cache[14] || (_cache[14] = $event => $setup.modifyApi2())
        }, {
          default: _withCtx(() => [_createTextVNode("提交新增")]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    }, 8, ["data"])]),
    _: 1
  }, 8, ["modelValue"])], 64);
}