/**
 * userRequest模块接口列表
 */

import axios from "@/util/http"; // 导入http中创建的axios实例
import qs from "qs";
import cookies from "js-cookie";
import util from "@/util/util";

let Base64 = require("js-base64").Base64;

const userRequest = {
  // 用户登录
  login(params) {
    var config = {
      headers: {
        Authorization: "Basic " + Base64.encode("erp-client" + ":" + "secret"),
      },
    };
    return axios
      .post(`/api/oauth/token`, qs.stringify(params), config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        cookies.remove("token");
        throw error;
      });
  },
  refreshToken(refresh_token) {
    var params = {
      grant_type: "refresh_token",
      refresh_token: refresh_token,
    };

    var config = {
      headers: {
        Authorization: "Basic " + Base64.encode("erp-client" + ":" + "secret"),
      },
    };
    return axios
      .post(`/api/oauth/token`, qs.stringify(params), config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        throw error;
      });
  },
  getUserDetail(retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    console.log("token = " + tokenJson.access_token);
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .get(`/api/user/getDetail`, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  getUserOrganization(retry) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    console.log("token = " + tokenJson.access_token);
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
      shouldRetry: retry,
    };
    return axios
      .get(`/api/user/getUserOrganization`, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  getUserPage(userPageROPageInfo) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };
    return axios
      .post(`/api/user/getPage`, userPageROPageInfo, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  registerUser(userRegisterRO) {
    var config = {
      headers: {
        ContentType: "application/json;charset=UTF-8",
      },
    };
    return axios
      .post(`/api/user/register`, userRegisterRO, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  addUser(userAddRO) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };
    return axios
      .post(`/api/user/add`, userAddRO, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  updateUser(userUpdateRO) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };
    return axios
      .post(`/api/user/update`, userUpdateRO, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  deleteUser(userDeleteRO) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };
    return axios
      .post(`/api/user/delete`, userDeleteRO, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  verificationUserEmail(verificationUserEmailRO) {
    var config = {
      headers: {
        ContentType: "application/json;charset=UTF-8",
      },
    };
    return axios
      .post(`/api/user/verificationUserEmail`, verificationUserEmailRO, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  approveUser(userId) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };
    var param = { userId: userId };
    param = util.parseParams(param);
    return axios
      .get(`/api/user/approveUser?` + param, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  getUserPermission() {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };

    return axios
      .get(`/api/user/getUserPermission`, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  verificationUserPassword(userPasswordRO) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };
    return axios
      .post(`/api/user/verificationUserPassword`, userPasswordRO, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  modifyUser(userModifyRO) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      return Promise.reject({ code: "TOKEN_MISSING" });
    }
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };
    return axios
      .post(`/api/user/modifyUser`, userModifyRO, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};

export default userRequest;
