export default {
  name: "FolderCard",
  props: {
    fileName: String,
    fileDate: String,
    url: String,
    iconSize: {
      type: String,
      default: "33px"
    },
    iconMargin: {
      type: String,
      default: "8px"
    },
    contentMarginRight: {
      type: String,
      default: "20px"
    },
    close: {
      type: Boolean,
      default: true
    }
  },
  setup(props, {
    emit
  }) {
    let enUrl = props.url;
    if (props.fileName) {
      if (props.fileName.toLowerCase().endsWith(".pdf") == true) {
        enUrl = "/pdfjs/web/viewer.html?file=" + encodeURIComponent(props.url);
      }
    }
    const removeDiv = () => {
      emit("remove");
    };
    return {
      removeDiv,
      enUrl
    };
  }
};