import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-05066e66"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "container"
};
const _hoisted_2 = ["href"];
const _hoisted_3 = {
  class: "file-name"
};
const _hoisted_4 = {
  class: "date"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_svg_icon = _resolveComponent("svg-icon");
  const _component_el_button = _resolveComponent("el-button");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_svg_icon, {
    iconName: "folder",
    iconColor: '#9e9e9e',
    iconSize: $props.iconSize,
    style: _normalizeStyle({
      margin: $props.iconMargin
    })
  }, null, 8, ["iconSize", "style"]), _createElementVNode("div", {
    class: "content",
    style: _normalizeStyle({
      marginRight: $props.contentMarginRight
    })
  }, [_createElementVNode("a", {
    href: $setup.enUrl,
    target: "_blank"
  }, [_createElementVNode("div", _hoisted_3, _toDisplayString($props.fileName), 1)], 8, _hoisted_2), _createElementVNode("div", _hoisted_4, _toDisplayString($props.fileDate), 1)], 4), $props.close == true ? (_openBlock(), _createBlock(_component_el_button, {
    key: 0,
    class: "delete-button button-default noborder small",
    circle: "",
    onClick: $setup.removeDiv
  }, {
    default: _withCtx(() => [_createVNode(_component_svg_icon, {
      iconName: "delete",
      iconSize: "21px"
    })]),
    _: 1
  }, 8, ["onClick"])) : _createCommentVNode("", true)]);
}