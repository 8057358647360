import { defineComponent, ref, onMounted, onUnmounted } from "vue";
export default defineComponent({
  emits: {
    "pane-size-change": null
  },
  setup(props, context) {
    const pane = ref({});
    const size = ref({
      width: 0,
      height: 0
    });
    const resizeObserver = new ResizeObserver(entries => {
      if (pane.value != null) {
        var paneClientRect = pane.value.parentNode.getBoundingClientRect();
        size.value.width = paneClientRect.width;
        size.value.height = paneClientRect.height;
        context.emit("pane-size-change", {
          width: size.value.width,
          height: size.value.height
        });
        console.log(size.value);
      }
    });
    onMounted(() => {
      if (pane.value != null) {
        resizeObserver.observe(pane.value.parentNode);
      }
    });
    onUnmounted(() => {
      if (pane.value != null) {
        resizeObserver.unobserve(pane.value.parentNode);
      }
    });
    return {
      pane,
      size
    };
  },
  methods() {}
});