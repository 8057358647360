import "core-js/modules/es.array.push.js";
import Tailoring from "../components/Tailoring.vue";
import { reactive, ref, onMounted, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import util from "@/util/util";
import cookies from "js-cookie";
import { Close } from "@element-plus/icons-vue";

import { ElNotification, ElMessage, ElMessageBox } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/notification/style/css';
import 'element-plus/es/components/message/style/css';
import 'element-plus/es/components/message-box/style/css';
export default {
  name: "RegisterLogin",
  components: {
    Tailoring,
    Close
  },
  setup() {
    window.localStorage.removeItem("union_token");
    const {
      proxy
    } = getCurrentInstance();
    const router = useRouter();
    const loginBox = ref(null);
    const control = reactive({
      loginTab: 0,
      loginType: 0,
      avatardialogVisible: false,
      paneVisable: false,
      resetPasswordStep: 0
    });
    const vendorProps = {
      value: "vendorId",
      label: "vendorName"
    };
    const storeProps = {
      value: "storeId",
      label: "storeName"
    };
    const brandProps = {
      value: "organizationId",
      label: "organizationName",
      children: "children"
    };
    const userTypeData = [{
      label: "品牌",
      value: 1
    }, {
      label: "供应商",
      value: 2
    }, {
      label: "店铺",
      value: 3
    }];
    const storeData = reactive([]);
    const vendorData = reactive([]);
    const organizationData = reactive([]);
    const usernameLoginForm = ref(null);
    const mobileLoginForm = ref(null);
    const resetPasswordForm = ref(null);
    const resetPasswordNextForm = ref(null);
    const mobileCheckForm = ref(null);
    const loginFormData = reactive({
      mobile: "",
      username: "",
      password: "",
      remember: false
    });
    const loginFormRules = reactive({
      username: [{
        required: true,
        message: "请输入邮箱",
        trigger: "blur"
      }, {
        pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
        message: "请输入有效的邮箱",
        trigger: "blur"
      }],
      password: [{
        required: true,
        message: "请输入密码",
        trigger: "blur"
      }],
      mobile: [{
        required: true,
        message: "请输入手机号码",
        trigger: "blur"
      }, {
        pattern: /^1[3-9]\d{9}$/,
        message: "请输入有效的手机号码",
        trigger: "blur"
      }],
      smsCode: [{
        required: true,
        message: "请输入验证码",
        trigger: "blur"
      }, {
        min: 6,
        max: 6,
        message: "请输入正确的验证码",
        trigger: "blur"
      }]
    });
    const smsControl = reactive({
      smsCooldownSecond: 60,
      smsDisabled: false,
      smsButtonText: "发送验证码"
    });
    const smsCooldown = () => {
      smsControl.smsCooldownHandle = setInterval(function () {
        smsControl.smsCooldownSecond--;
        if (smsControl.smsCooldownSecond == 0) {
          smsControl.smsDisabled = false;
          clearInterval(smsControl.smsCooldownHandle);
          smsControl.smsButtonText = "发送验证码";
        } else {
          smsControl.smsButtonText = smsControl.smsCooldownSecond + "秒";
        }
      }.bind(this), 1000);
    };
    const getStore = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.storeRequest.getStoreAll(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          ElNotification({
            title: "失败",
            message: error,
            type: "error",
            duration: 3000
          });
          resolve(null);
        });
      });
    };
    const getVendor = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.vendorRequest.getVendorAll(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          ElNotification({
            title: "失败",
            message: error,
            type: "error",
            duration: 3000
          });
          resolve(null);
        });
      });
    };
    const getOrganizationBrand = async () => {
      return new Promise((resolve, reject) => {
        proxy.$api.organizationRequest.getOrganizationBrand(true).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          ElNotification({
            title: "失败",
            message: error,
            type: "error",
            duration: 3000
          });
          resolve(null);
        });
      });
    };
    const onLoginSubmit = (e, loginType) => {
      e.preventDefault();
      let loginForm = usernameLoginForm;
      let params = {
        username: loginFormData.username,
        password: loginFormData.password,
        grant_type: "password",
        loginType: 1
      };
      if (loginType == 2) {
        loginForm = mobileLoginForm;
        params.username = loginFormData.mobile;
        params.password = loginFormData.smsCode;
        params.loginType = 2;
        loginFormData.remember = false;
      }
      loginForm.value.validate(valid => {
        if (valid) {
          onLogin(params, loginFormData.remember);
        }
      });
    };
    const onLogin = (params, remember) => {
      proxy.$api.userRequest.login(params).then(res => {
        console.log(res);
        res.avatar = null;
        if (remember === true) {
          sessionStorage.removeItem("token");
          cookies.set("token", JSON.stringify(res), {
            expires: 30
          });
        } else {
          cookies.remove("token");
          sessionStorage.setItem("token", JSON.stringify(res));
        }
        proxy.$api.userRequest.getUserPermission().then(res => {
          if (res.code === "200000") {
            if (res.result.dataCode === "202016") {
              let userInfo = res.result.data;
              window.localStorage.setItem("pnp_user_info", JSON.stringify(userInfo));
              router.push({
                path: "/activity-browser"
              });
            }
          } else {
            ElMessage.error("登录失败");
          }
        }).catch(error => {
          ElMessage.error("登录失败");
        });
      }).catch(error => {
        if (error.error == "unauthorized") {
          ElMessage.error(error.error_description);
        } else {
          ElMessage.error("登录失败");
        }
      });
    };
    const registerForm = ref(null);
    const registerFormFirst = ref(null);
    const registerFormData = reactive({
      password: null,
      passwordAgain: null,
      lastName: null,
      firstName: null,
      avatar: "https://avatars2.githubusercontent.com/u/15681693?s=460&v=4",
      mobile: null,
      smsCode: null,
      email: null,
      emailCode: null,
      address: null,
      type: 1,
      managerId: null,
      uuid: null
    });
    const registerFormRules = reactive({
      lastName: [{
        required: true,
        message: "请输入姓氏",
        trigger: "blur"
      }],
      firstName: [{
        required: true,
        message: "请输入名称",
        trigger: "blur"
      }],
      type: [{
        required: true,
        message: "请选择用户类型",
        trigger: "blur"
      }],
      managerId: [{
        required: true,
        message: "请选择",
        trigger: "blur"
      }]
    });
    const registerFormNextRules = reactive({
      mobile: [{
        required: true,
        message: "请输入手机号码",
        trigger: "blur"
      }, {
        min: 11,
        max: 11,
        message: "请输入正确的手机号码",
        trigger: "blur"
      }],
      smsCode: [{
        required: true,
        message: "请输入验证码",
        trigger: "blur"
      }, {
        min: 6,
        max: 6,
        message: "请输入正确的验证码",
        trigger: "blur"
      }],
      email: [{
        required: true,
        message: "请输入邮箱",
        trigger: "blur"
      }, {
        pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
        message: "请输入有效的邮箱",
        trigger: "blur"
      }],
      emailCode: [{
        required: true,
        message: "请输入验证码",
        trigger: "blur"
      }, {
        min: 6,
        max: 6,
        message: "请输入正确的验证码",
        trigger: "blur"
      }],
      password: [{
        required: true,
        message: "请输入密码",
        trigger: "blur"
      }, {
        pattern: /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d!@#$%^&*()\-_=\+[\]{}|;:'",<.>\/?]{8,}$/,
        message: "密码至少8位，并且包含英文大小写字母",
        trigger: "blur"
      }, {
        validator: (rule, value, callback) => {
          if (registerFormData.passwordAgain != null && registerFormData.passwordAgain != "" && registerFormData.passwordAgain != registerFormData.password) {
            callback(new Error("两次输入的密码不一致"));
          } else {
            callback();
          }
        },
        trigger: "blur"
      }],
      passwordAgain: [{
        required: true,
        message: "请再次输入密码",
        trigger: "blur"
      }, {
        pattern: /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d!@#$%^&*()\-_=\+[\]{}|;:'",<.>\/?]{8,}$/,
        message: "密码至少8位，并且包含英文大小写字母",
        trigger: "blur"
      }, {
        validator: (rule, value, callback) => {
          if (registerFormData.password != null && registerFormData.password != "" && registerFormData.passwordAgain != registerFormData.password) {
            callback(new Error("两次输入的密码不一致"));
          } else {
            callback();
          }
        },
        trigger: "blur"
      }]
    });
    const onRegisterNext = e => {
      e.preventDefault();
      registerFormFirst.value.validate(valid => {
        if (valid) {
          control.loginTab = 2;
        }
      });
    };
    const onRegisterSubmit = e => {
      e.preventDefault();
      registerForm.value.validate(valid => {
        if (valid) {
          if (registerFormData.passwordAgain != registerFormData.password) {
            ElNotification({
              title: "失败",
              message: "两次密码输入不同",
              type: "error",
              duration: 3000
            });
            return;
          }
          proxy.$api.userRequest.registerUser(registerFormData).then(res => {
            if (res.code === "200000") {
              if (res.result.dataCode === "202009") {
                loginClick();
                ElMessageBox.alert("注册成功，请等待审核成功后即可登录使用", "提示", {
                  confirmButtonText: "确定",
                  type: "success"
                });
              } else {
                ElNotification({
                  title: "失败",
                  message: res.result.promptMsg,
                  type: "error",
                  duration: 3000
                });
              }
            } else {
              ElNotification({
                title: "失败",
                message: res.msg,
                type: "error",
                duration: 3000
              });
            }
          }).catch(error => {
            console.log(error);
            ElNotification({
              title: "错误",
              message: error,
              type: "error",
              duration: 3000
            });
          });
        }
      });
    };
    onMounted(async () => {
      window.addEventListener("resize", util.debounce(windowResize, 0));
      windowResize();
      let store = await getStore();
      if (store == null) {
        return;
      }
      storeData.push(...store);
      let vendor = await getVendor();
      if (vendor == null) {
        return;
      }
      vendorData.push(...vendor);
      let organizationList = await getOrganizationBrand();
      if (organizationList == null) {
        return;
      }
      const organizationDept = {};
      organizationData.length = 0;
      for (var organization of organizationList) {
        if (organization.depth == 1) {
          organizationDept[organization.path + organization.organizationId] = organization;
          organizationData.push(organization);
        } else {
          if (organizationDept[organization.path].children == null) {
            organizationDept[organization.path].children = [];
          }
          organizationDept[organization.path + "/" + organization.organizationId] = organization;
          organizationDept[organization.path].children.push(organization);
        }
      }
    });
    const windowResize = () => {
      var width = window.innerWidth;
      let mainBody = document.getElementById("main");
      if (width > 400) {
        if (loginBox.vaue && loginBox.vaue.style) {
          loginBox.value.style.minHeight = "unset";
        }
      } else {
        if (loginBox.vaue && loginBox.vaue.style) {
          loginBox.value.style.minHeight = mainBody.style.height;
        }
      }
    };
    function imgUrlbase64(code) {
      // console.log(code)
      registerFormData.avatar = code;
      control.avatardialogVisible = false;
    }
    const loginClick = () => {
      control.loginTab = 0;
      control.paneVisable = true;
      if (usernameLoginForm.value) {
        usernameLoginForm.value.resetFields();
      }
      if (mobileLoginForm.value) {
        mobileLoginForm.value.resetFields();
      }
      if (registerForm.value) {
        registerForm.value.resetFields();
      }
    };
    const createImageWithText = (text, bgColor, width, height) => {
      // 创建canvas元素
      var canvas = document.createElement("canvas");
      canvas.width = width;
      canvas.height = height;

      // 获取绘图上下文
      var ctx = canvas.getContext("2d");

      // 设置背景颜色
      ctx.fillStyle = bgColor;
      ctx.fillRect(0, 0, width, height);

      // 设置字体样式并居中显示文字
      ctx.fillStyle = "white"; // 文字颜色
      ctx.font = "bold 80px Arial"; // 修改为适当的字体和大小
      ctx.textAlign = "center";
      ctx.textBaseline = "middle";
      ctx.fillText(text, width / 2, height / 2);

      // 将canvas内容转为Base64编码的图片
      return canvas.toDataURL();
    };

    // 使用函数创建图像并转为Base64
    const firstNameChange = value => {
      console.log(value);
      if (value != null && value.length > 0) {
        var base64Image = createImageWithText(value[0], "#55ac68", 150, 150);
        registerFormData.avatar = base64Image;
      } else {
        registerFormData.avatar = null;
      }
    };
    const onSendSms = () => {
      var patt = /^1[3-9]\d{9}$/;
      if (patt.test(loginFormData.mobile) == false) {
        ElNotification({
          title: "失败",
          message: "请输入有效的手机号码",
          type: "error",
          duration: 6000
        });
        return;
      }
      proxy.$api.authorizationRequest.sendLoginVerificationCode(loginFormData.mobile).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "201101") {
            ElNotification({
              title: "成功",
              message: "验证码发送成功,请查看手机",
              type: "success",
              duration: 3000
            });
            smsControl.smsCooldownSecond = 60;
            smsControl.smsDisabled = true;
            smsControl.smsButtonText = smsControl.smsCooldownSecond + "秒";
            smsCooldown();
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        ElNotification({
          title: "失败",
          message: "验证码发送失败",
          type: "error",
          duration: 6000
        });
      });
    };
    const smsRegisterControl = reactive({
      smsCooldownSecond: 60,
      smsDisabled: false,
      smsButtonText: "发送验证码"
    });
    const smsRegisterCooldown = () => {
      smsRegisterControl.smsCooldownHandle = setInterval(function () {
        smsRegisterControl.smsCooldownSecond--;
        if (smsRegisterControl.smsCooldownSecond == 0) {
          smsRegisterControl.smsDisabled = false;
          clearInterval(smsRegisterControl.smsCooldownHandle);
          smsRegisterControl.smsButtonText = "发送验证码";
        } else {
          smsRegisterControl.smsButtonText = smsRegisterControl.smsCooldownSecond + "秒";
        }
      }.bind(this), 1000);
    };
    const onSendRegisterSms = () => {
      var patt = /^1[3-9]\d{9}$/;
      if (patt.test(registerFormData.mobile) == false) {
        ElNotification({
          title: "失败",
          message: "请输入有效的手机号码",
          type: "error",
          duration: 6000
        });
        return;
      }
      proxy.$api.authorizationRequest.sendRegisterVerificationCode(registerFormData.mobile).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "201101") {
            ElNotification({
              title: "成功",
              message: "验证码发送成功,请查看手机",
              type: "success",
              duration: 3000
            });
            smsRegisterControl.smsCooldownSecond = 60;
            smsRegisterControl.smsDisabled = true;
            smsRegisterControl.smsButtonText = smsRegisterControl.smsCooldownSecond + "秒";
            smsRegisterCooldown();
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        ElNotification({
          title: "失败",
          message: "验证码发送失败",
          type: "error",
          duration: 6000
        });
      });
    };
    const emailRegisterControl = reactive({
      emailCooldownSecond: 60,
      emailDisabled: false,
      emailButtonText: "发送验证码"
    });
    const emailRegisterCooldown = () => {
      emailRegisterControl.emailCooldownHandle = setInterval(function () {
        emailRegisterControl.emailCooldownSecond--;
        if (emailRegisterControl.emailCooldownSecond == 0) {
          emailRegisterControl.emailDisabled = false;
          clearInterval(emailRegisterControl.emailCooldownHandle);
          emailRegisterControl.emailButtonText = "发送验证码";
        } else {
          emailRegisterControl.emailButtonText = emailRegisterControl.emailCooldownSecond + "秒";
        }
      }.bind(this), 1000);
    };
    const onSendRegisterEmail = () => {
      var patt = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (patt.test(registerFormData.email) == false) {
        ElNotification({
          title: "失败",
          message: "请输入有效的邮箱",
          type: "error",
          duration: 6000
        });
        return;
      }
      proxy.$api.authorizationRequest.sendRegisterEmailCode(registerFormData.email).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "201105") {
            ElNotification({
              title: "成功",
              message: "验证码发送成功,请查收邮箱",
              type: "success",
              duration: 3000
            });
            emailRegisterControl.emailCooldownSecond = 60;
            emailRegisterControl.emailDisabled = true;
            emailRegisterControl.emailButtonText = emailRegisterControl.emailCooldownSecond + "秒";
            emailRegisterCooldown();
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        ElNotification({
          title: "失败",
          message: "验证码发送失败",
          type: "error",
          duration: 6000
        });
      });
    };
    const resetPasswordFormData = reactive({
      email: "",
      password: "",
      passwordAgain: "",
      emailCode: ""
    });
    const resetPasswordFormRules = reactive({
      email: [{
        required: true,
        message: "请输入邮箱",
        trigger: "blur"
      }, {
        pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
        message: "请输入有效的邮箱",
        trigger: "blur"
      }],
      emailCode: [{
        required: true,
        message: "请输入验证码",
        trigger: "none"
      }, {
        min: 6,
        max: 6,
        message: "请输入正确的验证码",
        trigger: "none"
      }],
      password: [{
        required: true,
        message: "请输入密码",
        trigger: "blur"
      }, {
        pattern: /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d!@#$%^&*()\-_=\+[\]{}|;:'",<.>\/?]{8,}$/,
        message: "密码至少8位，并且包含英文大小写字母",
        trigger: "blur"
      }, {
        validator: (rule, value, callback) => {
          if (resetPasswordFormData.passwordAgain != null && resetPasswordFormData.passwordAgain != "" && resetPasswordFormData.passwordAgain != resetPasswordFormData.password) {
            callback(new Error("两次输入的密码不一致"));
          } else {
            callback();
          }
        },
        trigger: "blur"
      }],
      passwordAgain: [{
        required: true,
        message: "请再次输入密码",
        trigger: "blur"
      }, {
        pattern: /^(?=.*[a-z])(?=.*[A-Z])[a-zA-Z\d!@#$%^&*()\-_=\+[\]{}|;:'",<.>\/?]{8,}$/,
        message: "密码至少8位，并且包含英文大小写字母",
        trigger: "blur"
      }, {
        validator: (rule, value, callback) => {
          if (resetPasswordFormData.password != null && resetPasswordFormData.password != "" && resetPasswordFormData.passwordAgain != resetPasswordFormData.password) {
            callback(new Error("两次输入的密码不一致"));
          } else {
            callback();
          }
        },
        trigger: "blur"
      }]
    });
    const resetPasswordControl = reactive({
      emailCooldownSecond: 60,
      emailDisabled: false,
      emailButtonText: "发送验证码"
    });
    const resetPasswordCooldown = () => {
      resetPasswordControl.emailCooldownHandle = setInterval(function () {
        resetPasswordControl.emailCooldownSecond--;
        if (resetPasswordControl.emailCooldownSecond == 0) {
          resetPasswordControl.emailDisabled = false;
          clearInterval(resetPasswordControl.emailCooldownHandle);
          resetPasswordControl.emailButtonText = "发送验证码";
        } else {
          resetPasswordControl.emailButtonText = resetPasswordControl.emailCooldownSecond + "秒";
        }
      }.bind(this), 1000);
    };
    const onSendResetPassword = () => {
      var patt = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (patt.test(resetPasswordFormData.email) == false) {
        ElNotification({
          title: "失败",
          message: "请输入有效的邮箱",
          type: "error",
          duration: 6000
        });
        return;
      }
      proxy.$api.authorizationRequest.sendResetPasswordEmailCode(resetPasswordFormData.email).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "201112") {
            ElNotification({
              title: "成功",
              message: "验证码发送成功,请查收邮箱",
              type: "success",
              duration: 3000
            });
            resetPasswordControl.emailCooldownSecond = 60;
            resetPasswordControl.emailDisabled = true;
            resetPasswordControl.emailButtonText = resetPasswordControl.emailCooldownSecond + "秒";
            resetPasswordCooldown();
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        ElNotification({
          title: "失败",
          message: "验证码发送失败",
          type: "error",
          duration: 6000
        });
      });
    };
    const onCheckResetPasswordNext = () => {
      var patt = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (patt.test(resetPasswordFormData.email) == false) {
        ElNotification({
          title: "失败",
          message: "请输入有效的邮箱",
          type: "error",
          duration: 6000
        });
        return;
      }
      resetPasswordForm.value.validate(valid => {
        if (valid) {
          proxy.$api.authorizationRequest.checkResetPasswordEmailCode(resetPasswordFormData.email, resetPasswordFormData.emailCode).then(res => {
            if (res.code === "200000") {
              if (res.result.dataCode === "201114") {
                control.resetPasswordStep = 1;
              } else {
                ElNotification({
                  title: "失败",
                  message: res.result.promptMsg,
                  type: "error",
                  duration: 6000
                });
              }
            } else {
              ElNotification({
                title: "失败",
                message: res.msg,
                type: "error",
                duration: 6000
              });
            }
          }).catch(error => {
            ElNotification({
              title: "失败",
              message: "验证码发送失败",
              type: "error",
              duration: 6000
            });
          });
        }
      });
    };
    const onResetPassword = () => {
      var patt = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (patt.test(resetPasswordFormData.email) == false) {
        ElNotification({
          title: "失败",
          message: "请输入有效的邮箱",
          type: "error",
          duration: 6000
        });
        return;
      }
      resetPasswordNextForm.value.validate(valid => {
        if (valid) {
          let resetPasswordRO = {
            email: resetPasswordFormData.email,
            code: resetPasswordFormData.emailCode,
            password: resetPasswordFormData.password
          };
          proxy.$api.authorizationRequest.resetPassword(resetPasswordRO).then(res => {
            if (res.code === "200000") {
              if (res.result.dataCode === "201117") {
                control.resetPasswordStep = 2;
              } else {
                ElNotification({
                  title: "失败",
                  message: res.result.promptMsg,
                  type: "error",
                  duration: 6000
                });
              }
            } else {
              ElNotification({
                title: "失败",
                message: res.msg,
                type: "error",
                duration: 6000
              });
            }
          }).catch(error => {
            ElNotification({
              title: "失败",
              message: "验证码发送失败",
              type: "error",
              duration: 6000
            });
          });
        }
      });
    };
    const mobileCheckFormData = reactive({
      uuid: "111",
      mobile: "",
      smsCode: null
    });
    const mobileCheckFormRules = reactive({
      mobile: [{
        required: true,
        message: "请输入手机号码",
        trigger: "blur"
      }, {
        pattern: /^1[3-9]\d{9}$/,
        message: "请输入有效的手机号码",
        trigger: "blur"
      }],
      smsCode: [{
        required: true,
        message: "请输入验证码",
        trigger: "blur"
      }, {
        min: 6,
        max: 6,
        message: "请输入正确的验证码",
        trigger: "blur"
      }]
    });
    const mobileCheckControl = reactive({
      smsCooldownSecond: 60,
      smsDisabled: false,
      smsButtonText: "发送验证码",
      message: "",
      buttonText: "快速绑定",
      submitType: 1 //1快速绑定2注册账号
    });

    const mobileCheckCooldown = () => {
      mobileCheckControl.smsCooldownHandle = setInterval(function () {
        mobileCheckControl.smsCooldownSecond--;
        if (mobileCheckControl.smsCooldownSecond == 0) {
          mobileCheckControl.smsDisabled = false;
          clearInterval(mobileCheckControl.smsCooldownHandle);
          mobileCheckControl.smsButtonText = "发送验证码";
        } else {
          mobileCheckControl.smsButtonText = mobileCheckControl.smsCooldownSecond + "秒";
        }
      }.bind(this), 1000);
    };
    const onSendMobileCheckSms = () => {
      var patt = /^1[3-9]\d{9}$/;
      if (patt.test(mobileCheckFormData.mobile) == false) {
        ElNotification({
          title: "失败",
          message: "请输入有效的手机号码",
          type: "error",
          duration: 6000
        });
        return;
      }
      proxy.$api.authorizationRequest.sendWxVerificationCode(mobileCheckFormData.mobile).then(res => {
        if (res.code === "200000") {
          if (res.result.dataCode === "201101") {
            ElNotification({
              title: "成功",
              message: "验证码发送成功,请查看手机",
              type: "success",
              duration: 3000
            });
            mobileCheckControl.smsCooldownSecond = 60;
            mobileCheckControl.smsDisabled = true;
            mobileCheckControl.smsButtonText = mobileCheckControl.smsCooldownSecond + "秒";
            mobileCheckCooldown();
          } else {
            ElNotification({
              title: "失败",
              message: res.result.promptMsg,
              type: "error",
              duration: 6000
            });
          }
        } else {
          ElNotification({
            title: "失败",
            message: res.msg,
            type: "error",
            duration: 6000
          });
        }
      }).catch(error => {
        ElNotification({
          title: "失败",
          message: "验证码发送失败",
          type: "error",
          duration: 6000
        });
      });
    };
    const mobileInput = value => {
      if (value == null || value.length < 11) {
        return;
      }
      var patt = /^1[3-9]\d{9}$/;
      if (patt.test(value) == false) {
        mobileCheckControl.message = "手机号码无效";
      } else {
        mobileCheckControl.message = "";
        proxy.$api.authorizationRequest.checkMobileState(value).then(res => {
          if (res.code === "200000") {
            if (res.result.dataCode === "201122") {
              mobileCheckControl.message = "手机号码微信已绑定";
            } else if (res.result.dataCode === "201121") {
              mobileCheckControl.buttonText = "快速绑定";
              mobileCheckControl.submitType = 1;
            } else {
              mobileCheckControl.buttonText = "注删账户";
              mobileCheckControl.submitType = 2;
            }
          } else {
            ElNotification({
              title: "失败",
              message: res.msg,
              type: "error",
              duration: 6000
            });
          }
        }).catch(error => {
          ElNotification({
            title: "失败",
            message: error,
            type: "error",
            duration: 6000
          });
        });
      }
    };
    const onWxBind = () => {
      var patt = /^1[3-9]\d{9}$/;
      if (patt.test(mobileCheckFormData.mobile) == false) {
        ElNotification({
          title: "失败",
          message: "请输入有效的手机号码",
          type: "error",
          duration: 6000
        });
        return;
      }
      mobileCheckForm.value.validate(valid => {
        if (valid) {
          if (mobileCheckControl.submitType === 1) {
            let bindWxRO = {
              mobile: mobileCheckFormData.mobile,
              code: mobileCheckFormData.smsCode,
              uuid: mobileCheckFormData.uuid
            };
            proxy.$api.authorizationRequest.bindWx(bindWxRO).then(res => {
              if (res.code === "200000") {
                if (res.result.dataCode === "201127") {
                  let params = {
                    username: mobileCheckFormData.mobile,
                    password: mobileCheckFormData.uuid,
                    grant_type: "password",
                    uuid: mobileCheckFormData.uuid,
                    loginType: 2
                  };
                  onLogin(params, false);
                } else {
                  ElNotification({
                    title: "失败",
                    message: res.result.promptMsg,
                    type: "error",
                    duration: 6000
                  });
                }
              } else {
                ElNotification({
                  title: "失败",
                  message: res.msg,
                  type: "error",
                  duration: 6000
                });
              }
            }).catch(error => {
              ElNotification({
                title: "失败",
                message: error,
                type: "error",
                duration: 6000
              });
            });
          } else {
            registerFormData.mobile = mobileCheckFormData.mobile;
            registerFormData.smsCode = mobileCheckFormData.smsCode;
            registerFormData.uuid = mobileCheckFormData.uuid;
            control.loginTab = 1;
            control.paneVisable = true;
          }
        }
      });
    };
    var intervalId = null;
    const onWxLogin = () => {
      // control.loginTab = 5;
      // return;
      window.localStorage.removeItem("union_token");
      if (intervalId != null) {
        clearInterval(intervalId);
      }
      intervalId = setInterval(function () {
        const unionToken = window.localStorage.getItem("union_token");
        console.log(unionToken);
        if (unionToken != null) {
          const unionTokenJson = JSON.parse(unionToken);
          if (unionTokenJson.mobile != null) {
            let params = {
              username: unionTokenJson.mobile,
              password: unionTokenJson.uuid,
              grant_type: "password",
              uuid: unionTokenJson.uuid,
              loginType: 2
            };
            onLogin(params, false);
          } else {
            control.loginTab = 5;
            mobileCheckFormData.uuid = unionTokenJson.uuid;
          }
          clearInterval(intervalId);
          intervalId = null;
        }
      }, 1000);
      var wx = new WxLogin({
        self_redirect: true,
        id: "login_container",
        appid: "wxd3b6efc7ed196d5e",
        scope: "snsapi_login",
        redirect_uri: "https://servicechannel.online/wx-check",
        state: "",
        style: "",
        href: "data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHsKICBkaXNwbGF5OiBub25lOwp9Ci5pbXBvd2VyQm94IC53YWl0aW5nLnBhbmVsQ29udGVudCB7CiAgZGlzcGxheTogZmxleDsKICBmbGV4LWRpcmVjdGlvbjogY29sdW1uOwogIGFsaWduLWl0ZW1zOiBjZW50ZXI7Cn0KLmltcG93ZXJCb3ggLnFyY29kZS5saWdodEJvcmRlciB7CiAgd2lkdGg6IDE2OHB4Owp9Cg=="
      });
      control.loginTab = 4;
    };
    return {
      loginBox,
      control,
      vendorProps,
      storeProps,
      brandProps,
      usernameLoginForm,
      mobileLoginForm,
      loginFormData,
      loginFormRules,
      registerFormFirst,
      registerForm,
      registerFormData,
      registerFormRules,
      registerFormNextRules,
      userTypeData,
      vendorData,
      storeData,
      organizationData,
      onSendSms,
      onSendRegisterSms,
      smsControl,
      smsRegisterControl,
      onLoginSubmit,
      onRegisterNext,
      onRegisterSubmit,
      imgUrlbase64,
      loginClick,
      firstNameChange,
      onSendRegisterEmail,
      emailRegisterControl,
      resetPasswordForm,
      resetPasswordFormData,
      resetPasswordFormRules,
      resetPasswordControl,
      onSendResetPassword,
      onCheckResetPasswordNext,
      resetPasswordNextForm,
      onResetPassword,
      mobileCheckForm,
      mobileCheckFormData,
      mobileCheckFormRules,
      mobileCheckControl,
      onSendMobileCheckSms,
      mobileInput,
      onWxBind,
      onWxLogin
    };
  }
};