import axios from "axios";
import router from "../router";
import qs from "qs";
import cookies from "js-cookie";
let Base64 = require("js-base64").Base64;
import { ElMessageBox } from "element-plus";
import api from "../api";
var instance = axios.create({
  timeout: 10000 * 24
});
// 响应拦截器
instance.interceptors.response.use(response => {
  // 正常响应处理
  return response;
}, async error => {
  if (error.response) {
    switch (error.response.status) {
      // 返回401，清除token信息并跳转到登录页面
      case 401:
        var remember = false;
        let token = sessionStorage.getItem("token");
        if (token == null) {
          remember = true;
          token = cookies.get("token");
        }
        if (token == null) {
          debugger;
          if (error.response.data && error.response.data.error == "unauthorized") {
            return Promise.reject(error.response.data);
          }
          return Promise.reject({
            code: "TOKEN_MISSING"
          });
        }
        var tokenJson = JSON.parse(token);
        console.log("token = " + tokenJson.access_token);
        if (error.response.config.headers.Authorization.replace("Bearer ", "") !== tokenJson.access_token) {
          return axios.request(error.response.config);
        }
        return api.userRequest.refreshToken(tokenJson.refresh_token).then(data => {
          if (remember === true) {
            sessionStorage.removeItem("token");
            cookies.set("token", JSON.stringify(data), {
              expires: 30
            });
          } else {
            cookies.remove("token");
            sessionStorage.setItem("token", JSON.stringify(data));
          }
          if (error.response.config.headers.Authorization != null) {
            error.response.config.headers.Authorization = "Bearer " + data.access_token;
          }
          return axios.request(error.response.config);
        }).catch(error => {
          debugger;
          if (error.response && error.response.status == 400 && error.response.data.error == "invalid_grant") {
            ElNotification({
              message: "登录已失效，请重新登录",
              type: "error",
              duration: 3000
            });
          }
          sessionStorage.removeItem("token");
          cookies.remove("token");
          router.replace({
            path: "/"
          });
          return Promise.reject({
            code: "TOKEN_MISSING"
          });
        });

      // const refreshToken = async (token) => {
      //   return new Promise((resolve, reject) => {
      //     api.userRequest
      //       .refreshToken(token)
      //       .then((data) => {
      //         if (remember === true) {
      //           sessionStorage.removeItem("token");
      //           cookies.set("token", JSON.stringify(data), {
      //             expires: 30,
      //           });
      //         } else {
      //           cookies.remove("token");
      //           sessionStorage.setItem("token", JSON.stringify(data));
      //         }
      //         if (error.response.config.headers.Authorization != null) {
      //           error.response.config.headers.Authorization =
      //             "Bearer " + data.access_token;
      //         }

      //         return resolve();
      //       })
      //       .catch((error) => {
      //         if (
      //           error.response &&
      //           error.response.status == 400 &&
      //           error.response.data.error == "invalid_grant"
      //         ) {
      //           ElNotification({
      //             message: "登录已失效，请重新登录",
      //             type: "error",
      //             duration: 3000,
      //           });
      //         }
      //         console.log(error);
      //         // sessionStorage.removeItem("token");
      //         // cookies.remove("token");
      //         router.replace({
      //           path: "/",
      //         });
      //       });
      //   });
      // };

      // return refreshToken(tokenJson.refresh_token).then(function () {
      //   return axios.request(error.response.config);
      // });
    }
  }

  const originalConfig = error.config;
  // 检查是否需要重试
  if (originalConfig.shouldRetry) {
    while (true) {
      try {
        // 使用 Element UI 的确认框
        await ElMessageBox.confirm("请求失败，是否重试？", "错误", {
          confirmButtonText: "重试",
          cancelButtonText: "取消",
          type: "warning"
        });
        // 用户确认重试，重新发送请求
        return await axios(originalConfig);
      } catch (retryError) {
        if (retryError.response) {
          // 更新错误对象，以便在下次循环中使用新的错误信息
          error = retryError;
        } else {
          // 用户取消重试，中断循环
          break;
        }
      }
    }
  }

  // 不进行重试或用户取消重试
  return Promise.reject(error);
});
export default instance;