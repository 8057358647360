import axios from "@/util/http"; // 导入http中创建的axios实例
import cookies from "js-cookie";
import util from "@/util/util";

const authorizationRequest = {
  getSignature(directory) {
    let tokenJson = util.getToken();
    if (tokenJson == null) {
      throw "token not have";
    }
    console.log("token = " + tokenJson.access_token);
    var config = {
      headers: {
        Authorization: "Bearer " + tokenJson.access_token,
        ContentType: "application/json;charset=UTF-8",
      },
    };

    var param = { directory: directory };
    param = util.parseParams(param);
    return axios
      .get(`/api/authorization/getSignature?` + param, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  sendLoginVerificationCode(mobile) {
    var config = {
      headers: {
        ContentType: "application/json;charset=UTF-8",
      },
    };
    var param = { mobile: mobile };
    param = util.parseParams(param);

    return axios
      .get(`/api/authorization/sendLoginVerificationCode?` + param, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  checkLoginVerificationCode(mobile, code) {
    var config = {
      headers: {
        ContentType: "application/json;charset=UTF-8",
      },
    };
    var param = { mobile: mobile, code: code };
    param = util.parseParams(param);

    return axios
      .get(`/api/authorization/checkLoginVerificationCode?` + param, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  sendRegisterVerificationCode(mobile) {
    var config = {
      headers: {
        ContentType: "application/json;charset=UTF-8",
      },
    };
    var param = { mobile: mobile };
    param = util.parseParams(param);

    return axios
      .get(`/api/authorization/sendRegisterVerificationCode?` + param, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  checkRegisterVerificationCode(mobile, code) {
    var config = {
      headers: {
        ContentType: "application/json;charset=UTF-8",
      },
    };
    var param = { mobile: mobile, code: code };
    param = util.parseParams(param);

    return axios
      .get(`/api/authorization/checkRegisterVerificationCode?` + param, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  sendRegisterEmailCode(email) {
    var config = {
      headers: {
        ContentType: "application/json;charset=UTF-8",
      },
    };
    var param = { email: email };
    param = util.parseParams(param);

    return axios
      .get(`/api/authorization/sendRegisterEmailCode?` + param, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  checkRegisterEmailCode(email, code) {
    var config = {
      headers: {
        ContentType: "application/json;charset=UTF-8",
      },
    };
    var param = { email: email, code: code };
    param = util.parseParams(param);

    return axios
      .get(`/api/authorization/checkRegisterEmailCode?` + param, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  sendResetPasswordEmailCode(email) {
    var config = {
      headers: {
        ContentType: "application/json;charset=UTF-8",
      },
    };
    var param = { email: email };
    param = util.parseParams(param);

    return axios
      .get(`/api/authorization/sendResetPasswordEmailCode?` + param, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  checkResetPasswordEmailCode(email, code) {
    var config = {
      headers: {
        ContentType: "application/json;charset=UTF-8",
      },
    };
    var param = { email: email, code: code };
    param = util.parseParams(param);

    return axios
      .get(`/api/authorization/checkResetPasswordEmailCode?` + param, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  resetPassword(resetPasswordRO) {
    var config = {
      headers: {
        ContentType: "application/json;charset=UTF-8",
      },
    };

    return axios
      .post(`/api/authorization/resetPassword`, resetPasswordRO, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  checkUserByWxCode(code) {
    var config = {
      headers: {
        ContentType: "application/json;charset=UTF-8",
      },
    };
    var param = { code: code };
    param = util.parseParams(param);

    return axios
      .get(`/api/authorization/checkUserByWxCode?` + param, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  sendWxVerificationCode(mobile) {
    var config = {
      headers: {
        ContentType: "application/json;charset=UTF-8",
      },
    };
    var param = { mobile: mobile };
    param = util.parseParams(param);

    return axios
      .get(`/api/authorization/sendWxVerificationCode?` + param, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  checkMobileState(mobile) {
    var config = {
      headers: {
        ContentType: "application/json;charset=UTF-8",
      },
    };
    var param = { mobile: mobile };
    param = util.parseParams(param);

    return axios
      .get(`/api/authorization/checkMobileState?` + param, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
  bindWx(bindWxRO) {
    var config = {
      headers: {
        ContentType: "application/json;charset=UTF-8",
      },
    };

    return axios
      .post(`/api/authorization/bindWx`, bindWxRO, config)
      .then((response) => {
        console.log(response.data);
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  },
};

export default authorizationRequest;
