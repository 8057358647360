import { createApp, ref, provide, reactive } from "vue";
import App from "./App.vue";
import i18n from "./i18n";
import router from "./router";
import VXETable from "vxe-table";
import "vxe-table/lib/style.css";
import VueSignaturePad from "vue-signature-pad";
import api from "./api"; // 导入api接口
import store from "./store";

const app = createApp(App);

app.config.globalProperties.$api = api;
app.config.globalProperties.$userInfo = {};
app.use(store);
app.use(router);
app.use(i18n);
app.use(VXETable);
app.use(VueSignaturePad);
app.mount("#app");

const globalUser = reactive({});

app.provide("globalUser", globalUser);

const requireAll = (context) => context.keys().map(context);
const req = require.context("./assets/icons", false, /\.svg$/);
requireAll(req);
