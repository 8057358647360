import "core-js/modules/es.array.push.js";
import { defineComponent, reactive, ref, onMounted, onUnmounted, computed, getCurrentInstance } from "vue";
import SvgIcon from "../components/SvgIcon.vue";
import { useRouter } from "vue-router";
import permission from "@/util/permission";

import { ElNotification } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/notification/style/css';
export default defineComponent({
  name: "ActivityBrowser",
  components: {
    SvgIcon
  },
  setup() {
    const {
      proxy
    } = getCurrentInstance();
    const router = useRouter();
    const activityBrowserRO = reactive({
      searchKeyword: null
    });
    const selectActivityYear = ref(null);
    const activityBrowserData = reactive([]);
    const endActivityBrowserData = reactive([]);
    const endActivityYearData = reactive([]);
    const getActivityBrowser = async (activityBrowserRO, retry) => {
      return new Promise((resolve, reject) => {
        proxy.$api.activityRequest.getActivityBrowser(activityBrowserRO, retry).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    const getEndActivityYear = async (activityBrowserRO, retry) => {
      return new Promise((resolve, reject) => {
        proxy.$api.activityRequest.getEndActivityYear(activityBrowserRO, retry).then(res => {
          resolve(res.result.data);
        }).catch(error => {
          if (error.code == "TOKEN_MISSING") {
            resolve(null);
          } else {
            ElNotification({
              title: "失败",
              message: error,
              type: "error",
              duration: 3000
            });
          }
        });
      });
    };
    const activitySelectChange = async activityYear => {
      selectActivityYear.value = activityYear;
      let endBrowserData = await getActivityBrowser({
        searchKeyword: activityBrowserRO.searchKeyword,
        activityYear: activityYear
      }, true);
      endActivityBrowserData.length = 0;
      endActivityBrowserData.push(...endBrowserData);
    };
    onMounted(async () => {
      getActivityData();
    });
    const activityCreateClick = () => {
      router.push({
        path: "/activity-create"
      });
    };
    const searchClick = async () => {
      getActivityData();
    };
    const keyDownSearchClick = async value => {
      if (value.$event.keyCode == 13) {
        getActivityData();
      }
    };
    const getActivityData = async () => {
      let browserData = await getActivityBrowser(activityBrowserRO, true);
      activityBrowserData.length = 0;
      if (browserData != null) {
        activityBrowserData.push(...browserData);
      }
      endActivityBrowserData.length = 0;
      endActivityYearData.length = 0;
      let yearData = await getEndActivityYear(activityBrowserRO, true);
      if (yearData != null && yearData.length > 0) {
        endActivityYearData.push(...yearData);
        selectActivityYear.value = yearData[0].activityYear;
        let endBrowserData = await getActivityBrowser({
          searchKeyword: activityBrowserRO.searchKeyword,
          activityYear: yearData[0].activityYear
        }, true);
        endActivityBrowserData.push(...endBrowserData);
      }
    };
    const activityClick = activityId => {
      if (permission.havePermission("活动管理", "活动详情")) {
        router.push({
          path: "/activity-detail/" + activityId
        });
      }
    };
    return {
      selectActivityYear,
      activityBrowserRO,
      activityBrowserData,
      endActivityBrowserData,
      endActivityYearData,
      activitySelectChange,
      activityCreateClick,
      searchClick,
      keyDownSearchClick,
      activityClick,
      permission
    };
  }
});